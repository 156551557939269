.static-view {
	margin-top: var(--page-margin-top);

	ul {

		list-style: disc;
		padding: 0 0 0 25px;

		li {
			margin-bottom: 10px;
		}
	}

	&.blog-list-v1 {
		.blog-list-boxes {


			.blog-block {
				margin-bottom: 30px;

				img {
					border-radius: 8px 8px 0 0 !important;
					width: 100% !important;
					border: none !important;
					padding: 0 !important;
					height: 360px !important;
					object-fit: cover;

					@media (max-width: 991px) {
						height: 250px !important;
					}
				}
			}

			.lt-bl-cont {
				background-color: #f3f3f3;
				padding: 30px;
				padding-bottom: 70px;
				min-height: 410px;
				position: relative;
				border-radius: 0 0 8px 8px !important;

				@media (max-width: 1499px) {
					min-height: 480px;
				}

				@media (max-width: 1199px) {
					min-height: 540px;
				}

				@media (max-width: 991px) {
					min-height: 750px;
				}

				@media (max-width: 767px) {
					padding: 20px;
				}

				.btn-primary {
					position: absolute;
					right: 30px;
					bottom: 30px;

					@media (max-width: 767px) {
						position: static;
					}
				}


				h4 {
					margin-bottom: 10px;
					line-height: normal;

					a {
						text-decoration: none !important;
						text-transform: uppercase;
					}
				}
			}
		}
	}

}